import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Grid,
  Paper,
  Radio,
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { CompleteCreditCardForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';
import {
  Alert,
  DialogPayment3DS,
  CreditCardPreview,
} from '_components/elements';

/* */
const validationSchema = createValidationSchema({
  cvv: validators.cvv,
  dueDate: validators.dateFourDigits,
});

/* */
const styles = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  panel: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  radio: {
    padding: 0,
    marginRight: theme.spacing.unit * 2,
  },
  marginT: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class Payment extends React.Component {
  /* */
  static defaultProps = {
    amount: 5000,
    currency: 'EUR',
    display: 'row',
    numberOfInstallments: 1,
    defaultImprintUse: 'enabled',
    session3DS: null, // required
  };

  /* */
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      // data 3DS
      error: null,
      sessionId: null,
      showIframe: false,
    };

    this.id3D = null;
    this.form = React.createRef();
    this.imprintRequired = props.numberOfInstallments > 1;
  }

  /* */
  getGridItemProps() {
    const { display } = this.props;
    if (display === 'column') {
      return { xs: 12 };
    }

    return { xs: 12, sm: 9, md: 6 };
  }

  /* */
  componentDidMount() {
    const { imprint } = this.props;

    this.setState({ expanded: imprint && 'imprint' });

    // add listener 3ds posted response
    window.addEventListener('message', this.handleMesssage3DS);
  }

  /* */
  componentDidUpdate(prevProps) {
    const { imprint } = this.props;

    if (prevProps.imprint !== imprint) this.setState({ expanded: imprint && 'imprint' });
  }

  /* */
  componentWillUnmount() {
    // clean listener
    window.removeEventListener('message', this.handleMesssage3DS);
  }

  /* */
  handleChange = panel => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false });
  };

  // Catch iframe 3DS message
  handleMesssage3DS = (event) => {
    if (!event.data.type || event.data.type !== 'response3DS') return;

    const { status, id3D } = event.data;

    if (status === 'success') {
      this.id3D = id3D;
      this.setState({ showIframe: false });
      this.form.current.props.submitForm();
    } else {
      this.handleError3DS();
    }
  }

  /* */
  handleError3DS() {
    this.id3D = null;
    this.form.current.props.setSubmitting(false);
    this.setState({
      showIframe: false,
      error: "Erreur d'authentification 3D Secure.",
    });
  }

  /* */
  renderCardImprint() {
    const {
      amount,
      classes,
      imprint,
      payWithCardImprint,
      numberOfInstallments,
    } = this.props;

    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center">
          <CreditCardPreview
            brand={imprint.brand}
            number={imprint.number}
            dueDate={imprint.dueDate} />
        </Box>
        {imprint.isExpired && (
          <React.Fragment>
            <Alert
              variant="error"
              className={classes.marginT}>
                {'La carte est expirée.'}
            </Alert>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="outlined"
              className={classes.marginT}
              onClick={() => this.props.history.replace('/account/creditcard')}>
              {'Changer'}
            </Button>
          </React.Fragment>
        )}
        {imprint.isSoonExpired && (
          <Alert
            variant="warning"
            className={classes.marginT}>
              {'La carte arrive bientôt à expiration.'}
          </Alert>
        )}
        {!imprint.isExpired && (
          <React.Fragment>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              className={classes.marginT}
              onClick={payWithCardImprint}>
              {`Payer ${i18n.l('currency', amount / 100)}`}
            </Button>
            {numberOfInstallments > 1 && (
              <Typography
                component="p"
                align="center"
                variant="caption"
                className={classes.marginT}>
                {`Paiement en ${numberOfInstallments} mensualités`}
              </Typography>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  /* */
  renderCardImprintPanel() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const { imprintRequired } = this;

    if (imprintRequired) {
      return (
        <Grid item {...this.getGridItemProps()}>
          <Paper className={classes.paper}>
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Ma carte enregistrée'}
            </Typography>
            <br />
            {this.renderCardImprint()}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item {...this.getGridItemProps()}>
        <ExpansionPanel
          expanded={expanded === 'imprint'}
          onChange={this.handleChange('imprint')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}>
            <Radio
              className={classes.radio}
              checked={expanded === 'imprint'} />
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Ma carte enregistrée'}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panel}>
            {this.renderCardImprint()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }

  /* */
  getCardInfos = () => this.form.current.getExtraInfos();

  /* */
  onFormSubmit = (values, actions) => {
    const {
      amount,
      session3DS,
      payWithNewCard,
    } = this.props;
    const { id3D } = this;

    const newValues = {
      ...values,
      ...this.getCardInfos(),
      id3D,
    };

    if (id3D !== null) {
      this.id3D = null; // force regénération pour les soumissions suivantes
      payWithNewCard(newValues, actions);
      return;
    }

    // build unique 3DS sessionId
    const sessionId = amount
      + Number(moment().format('x'))
      + Math.floor(Math.random() * (10 ** 12));

    // store temporarily data
    sessionStorage.setItem(`cart_${sessionId}`, JSON.stringify({
      ...session3DS,
      amount,
      id: sessionId,
      creditCard: {
        cvv: values.cvv,
        number: values.number.replace(/\s/g, ''),
        dueDate: values.dueDate.replace(/\D/g, ''),
      },
    }));

    // load iframe 3DS
    this.setState({
      sessionId,
      error: null,
      showIframe: true,
    });
  }

  /* */
  renderNewCardForm() {
    const {
      amount,
      imprint,
      defaultImprintUse,
      numberOfInstallments,
    } = this.props;

    const { error } = this.state;
    const { imprintRequired } = this;

    const imprintUse = imprintRequired ? 'required' : defaultImprintUse;

    const submitText = `Payer ${i18n.l('currency', amount / 100)}`;
    const submitHintText = numberOfInstallments > 1 ? `Paiement en ${numberOfInstallments} mensualités` : null;

    return (
      <React.Fragment>
        {error && (
          <Alert variant="error">{error}</Alert>
        )}
        <Formik
          initialValues={{
            cvv: '',
            number: '',
            dueDate: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={this.onFormSubmit}
          validationSchema={validationSchema}
          render={props =>
            <CompleteCreditCardForm
              {...props}
              innerRef={this.form}
              submitButtonText={submitText}
              submitButtonHintText={submitHintText}
              imprintUse={imprint ? 'disabled' : imprintUse} />
          } />
      </React.Fragment>
    );
  }

  /* */
  renderNewCardPanel() {
    const {
      classes,
      imprint,
    } = this.props;
    const { expanded } = this.state;

    if (!imprint) {
      return (
        <Grid item {...this.getGridItemProps()}>
          <Paper className={classes.paper}>
            {this.renderNewCardForm()}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item {...this.getGridItemProps()}>
        <ExpansionPanel
          expanded={expanded === 'other'}
          onChange={this.handleChange('other')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}>
            <Radio
              className={classes.radio}
              checked={expanded === 'other'} />
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Autre carte bancaire'}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panel}>
            {this.renderNewCardForm()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }

  /* */
  renderPanels() {
    const { imprint } = this.props;
    const { imprintRequired } = this;

    return (
      <React.Fragment>
        {imprint && this.renderCardImprintPanel()}
        {(!imprint || !imprintRequired) && this.renderNewCardPanel()}
      </React.Fragment>
    );
  }

  /* */
  renderDialog3DS() {
    const { showIframe, sessionId } = this.state;

    if (!sessionId) return null;

    return (
      <DialogPayment3DS
        open={showIframe}
        sessionId={sessionId}
        onClose={() => this.handleError3DS()} />
    );
  }

  /* */
  render() {
    const { currency } = this.props;

    // Seule la devise EUR est acceptée
    if (currency !== 'EUR') {
      return (
        <Alert variant="warning">
          {`Actuellement, la devise ${currency} n'est pas acceptée pour le paiement en ligne.`}
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <Grid
          container
          spacing={40}
          justify="center">
          {this.renderPanels()}
        </Grid>
        {this.renderDialog3DS()}
      </React.Fragment>
    );
  }
}

const PaymentWithRouter = withRouter(Payment);

export default withStyles(styles)(PaymentWithRouter);
